var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-modal',{attrs:{"title":_vm.action + ' Branches(Cabang)',"dialogStyle":{ top: '10px' },"destroyOnClose":true},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('template',{slot:"footer"},[_c('a-button',{staticClass:"btn btn-outline-dark",on:{"click":_vm.handleCancel}},[_vm._v(" Cancel ")]),_c('a-button',{staticClass:"btn btn-outline-primary",attrs:{"loading":_vm.loading},on:{"click":function($event){return _vm.submit('ruleForm')}}},[_vm._v(" Submit ")])],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('a-form-model',{ref:"ruleForm",attrs:{"model":_vm.form,"rules":_vm.rules,"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol}},[_c('a-form-model-item',{ref:"kode",staticStyle:{"margin":"unset"},attrs:{"label":"Kode","prop":"kode"}},[_c('a-input',{attrs:{"placeholder":"Masukan kode"},on:{"blur":() => {
                  _vm.$refs.kode.onFieldBlur();
                }},model:{value:(_vm.form.kode),callback:function ($$v) {_vm.$set(_vm.form, "kode", $$v)},expression:"form.kode"}})],1),_c('a-form-model-item',{ref:"sandi_kantor",staticStyle:{"margin":"unset"},attrs:{"label":"Sandi Kantor","prop":"sandi_kantor"}},[_c('a-input',{attrs:{"placeholder":"Masukan sandi kantor"},on:{"blur":() => {
                  _vm.$refs.sandi_kantor.onFieldBlur();
                }},model:{value:(_vm.form.sandi_kantor),callback:function ($$v) {_vm.$set(_vm.form, "sandi_kantor", $$v)},expression:"form.sandi_kantor"}})],1),_c('a-form-model-item',{ref:"alamat",staticStyle:{"margin":"unset"},attrs:{"label":"Alamat","prop":"alamat"}},[_c('a-input',{attrs:{"placeholder":"Masukan alamat"},on:{"blur":() => {
                  _vm.$refs.alamat.onFieldBlur();
                }},model:{value:(_vm.form.alamat),callback:function ($$v) {_vm.$set(_vm.form, "alamat", $$v)},expression:"form.alamat"}})],1),_c('a-form-model-item',{ref:"keterangan",staticStyle:{"margin":"unset"},attrs:{"label":"Keterangan","prop":"keterangan"}},[_c('a-input',{attrs:{"placeholder":"Masukan keterangan"},on:{"blur":() => {
                  _vm.$refs.keterangan.onFieldBlur();
                }},model:{value:(_vm.form.keterangan),callback:function ($$v) {_vm.$set(_vm.form, "keterangan", $$v)},expression:"form.keterangan"}})],1),_c('a-form-model-item',{ref:"induk",staticStyle:{"margin":"unset"},attrs:{"label":"Induk","prop":"induk"}},[_c('a-input',{attrs:{"placeholder":"Masukan nomor induk"},on:{"blur":() => {
                  _vm.$refs.induk.onFieldBlur();
                }},model:{value:(_vm.form.induk),callback:function ($$v) {_vm.$set(_vm.form, "induk", $$v)},expression:"form.induk"}})],1),_c('a-form-model-item',{ref:"telp",staticStyle:{"margin":"unset"},attrs:{"label":"Telp","prop":"telp"}},[_c('a-input',{attrs:{"placeholder":"Masukan nomor telp"},on:{"blur":() => {
                  _vm.$refs.telp.onFieldBlur();
                }},model:{value:(_vm.form.telp),callback:function ($$v) {_vm.$set(_vm.form, "telp", $$v)},expression:"form.telp"}})],1),_c('a-form-model-item',{ref:"wa",staticStyle:{"margin":"unset"},attrs:{"label":"WhatsApp","prop":"wa"}},[_c('a-input',{attrs:{"placeholder":"Masukan nomor wa"},on:{"blur":() => {
                  _vm.$refs.wa.onFieldBlur();
                }},model:{value:(_vm.form.wa),callback:function ($$v) {_vm.$set(_vm.form, "wa", $$v)},expression:"form.wa"}})],1),_c('a-form-model-item',{ref:"kota",staticStyle:{"margin":"unset"},attrs:{"label":"Kota","prop":"kota"}},[_c('a-input',{attrs:{"placeholder":"Masukan kota"},on:{"blur":() => {
                  _vm.$refs.kota.onFieldBlur();
                }},model:{value:(_vm.form.kota),callback:function ($$v) {_vm.$set(_vm.form, "kota", $$v)},expression:"form.kota"}})],1)],1)],1)])],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }